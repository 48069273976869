

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onRouteUpdateDelayed = () => {
  //console.log("We can show loading indicator now")
}

exports.onRouteUpdate = ({ location }) => {
  //console.log('new pathname', location.pathname)
  // particlesJS.load('https://mattsha.de/js/particles-load.js','/particles.json'), function() {
  //   console.log('callback - particles.js config loaded')
  // }
}
